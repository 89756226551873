import * as React from "react"
/*
import Head from "../components/head.js"
import { graphql } from "gatsby"
import Img from "gatsby-plugin-image"
import Navigation from "../components/navigation.js"
*/
import NotFoundPage from "../pages/404.js"

export default function Tools() {
    return (
      <NotFoundPage />
    )
}
/*
{ 

export default function IndexPage ({data}) {
  return (
    <>
    
      <Head />
      
      <Navigation />
      
      <section className="main tools">
        <div>
          <h1>tools</h1>
          <ul className="card-list">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <li key={node.id}>
                <Link to={node.fields.slug}><img src={node.frontmatter.imgthumb.childImageSharp.original.src} alt={node.frontmatter.imgalt} /></Link>
                <h2><Link to={node.fields.slug}>{node.frontmatter.title}</Link></h2>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            imgthumb {
              childImageSharp {
                original {
                  src
                }
              }
            }
            imgalt
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

 }
 */